/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

// ** import variables
@import "./variables/variables";

// ** General **//
.content.app-content {
  padding: 0 !important;
}

// Sections styles
.section {
  padding-top: 60px;
  padding-bottom: 60px;
}

// full width container
.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

// container padding
.container {
  max-width: 100%;
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
}

@media (min-width: 992px) {
  .h-lg-100 {
    height: 100% !important;
  }
}

.fit-cover {
  object-fit: cover !important;
}

.z-index-9 {
  z-index: 9 !important;
}

// to prevent button hover (btn bootstrap class)
.prevent-hover:hover {
  border-color: transparent !important;
}

//** General End **//

// ** Navbar Start **\\

.header-navbar {
  transition: 0.3s;

  li {
    a {
      color: $secondary;
    }
  }
}

.header-navbar__transparent {
  transition: 0.3s;
  background-color: transparent !important;

  li {
    a {
      color: white !important;
      svg {
        color: white !important;
      }
    }
  }

  .logo__suntrepreneur {
    fill: white !important;
  }

  .dropdown-item {
    color: $secondary !important;
    svg {
      color: $secondary !important;
    }
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    color: $primary !important;
    svg {
      color: $primary !important;
    }
  }
  .dropdown-item:active {
    color: white !important;
    svg {
      color: white !important;
    }
  }
}

.nav-link-style.active {
  color: $primary !important;
  font-weight: bold;
}

.navbar-brand {
  margin-top: 0.75rem !important;
}
.main-menu .navbar-header .navbar-brand .brand-logo img {
  max-width: 150px;
  margin-top: 0.5rem;
}

.auth-wrapper .auth-inner .brand-logo img {
  width: 200px;
}

.header-navbar__transparent.header-navbar.navbar-shadow {
  box-shadow: unset;
}

@media (min-width: 768px) {
  .container,
  .navbar-container {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
}

.navbar-header li.nav-item.nav-toggle {
  margin-top: 0.3rem;
}

.main-menu.menu-fixed.menu-accordion.menu-shadow.expanded.menu-light
  ul.navigation.navigation-main {
  margin-top: 1rem;
}
// ** Navbar End **\\

.h-400 {
  height: 400px !important;
}

@media (max-width: 768px) {
  .h-sm-400 {
    height: 400px !important;
  }
}

// ** Banner Start ** \\
.image-banner__container {
  position: relative;
  width: 100%;
  height: 65vh;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.carousel-banner__container {
  position: relative;
  width: 100%;
  height: 65vh;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  white-space: pre-line;
}
// ** Banner End ** \\

// ** Blog Start ** \\
@media (min-width: 992px) {
  .content-detached.content-left .content-body {
    margin-right: calc(260px + 2rem) !important;
  }
}

.blog-card__img-container,
.courses-card__img-container {
  height: 350px;
  img {
    height: 100%;
  }
}
.blog-details__img-container,
.course-details__img-container {
  height: 300px;
  max-height: 350px;
  img {
    height: 100%;
    object-fit: cover;
  }
}

.blog-list-wrapper {
  .blog-title-truncate {
    height: 4.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .blog-content-truncate {
    height: 5rem;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    .blog-title-truncate {
      height: 7rem !important;
    }

    .blog-content-truncate {
      height: 8rem !important;
    }
  }
}

.blog-sidebar__img-container,
.course-sidebar__img-container {
  width: 100px;
  height: 70px;
}
// ** Blog  End ** \\

// ** Facebook post Start ** \\
.facebook-title-truncate {
  height: 4.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 4 !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .facebook-title-truncate {
    height: 7rem !important;
  }
}

.facebook-card__img-container {
  height: 350px;
  img {
    height: 100%;
    object-fit: cover;
  }
}
// ** Facebook post End ** \\

// ** Footer Start ** \\
.footer {
  background-color: #080808;
  color: white;
  a,
  h3 {
    color: white;
  }
}

.footer-latest-news__container {
  height: 80px;
}

.top-footer {
  margin-top: 40px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(211, 211, 211, 0.322);
  svg {
    color: $primary;
    margin-right: 0.75rem;
  }
}

.footer__last-news-img-container {
  min-width: 80px;
  max-width: 80px;
  min-height: 100%;
  max-height: 100%;

  margin-right: 0.75rem;

  img {
    width: 100%;
    height: 80px;
    object-fit: cover;
  }
}

.footer__last-news-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
// ** Footer End ** \\

// ** How it works Start **\\
.how-it-works__steps__img-container {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
  }
}

.how-it-works__title-number {
  min-width: 30px;
  height: 30px;
  background-color: $primary;
  border-radius: 50%;
  display: inline-flex;
  margin-right: 1rem;
  align-items: center;
  justify-content: center;
  span {
    color: white;
    font-weight: 600;
  }
}
// ** How it works End **\\

// ** Registration Code Input Start **\\
.registration__code-input {
  font-size: 1.5rem;
  width: 55px;
  border-radius: 10px;
  border: 1px solid #666;
  outline: 0;
  transition: 0.3s;
}

.registration__code-input:focus {
  border: 1px solid $primary;
  box-shadow: 0 3px 10px 0rgba (34, 41, 47, 0.1);
  transition: 0.3s;
}
// ** Registration Code Input End **\\

// Spinner Effect
.effect-1,
.effect-2,
.effect-3 {
  border-left-color: $primary !important;
}

// Banners Background Overlay
.bg-gradient-overlay {
  background: #000000;
  opacity: 0.5;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
  pointer-events: none;
}

// Modal
.modal-title {
  color: $primary;
  margin-bottom: 0;
  font-weight: bolder;
}

// disabled inputs fixes
.disabled-input-icon-bg {
  background-color: #efefef !important;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill:disabled,
textarea:-webkit-autofill:disabled,
select:-webkit-autofill:disabled {
  box-shadow: 0 0 0 30px #efefef inset !important;
  -webkit-box-shadow: 0 0 0 30px #efefef inset !important;
}

// Skeleton Loading
.react-loading-skeleton {
  line-height: unset !important;
}

// user profile
.user-profile__tabs {
  .nav-item {
    .nav-link {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom-color: #fbf1e1;
    }
    .nav-link.active {
      color: $primary;
      border-bottom-color: $primary;
    }
  }
}

.modal-open {
  padding-right: 0 !important;
}

// **  Start Breadcrumb **
.breadcrumb {
  padding-left: 0px !important;
}

@media (max-width: 648px) {
  .content-header .breadcrumb {
    display: flex !important;
  }
}
// **  End Breadcrumb **

// ** auth-register Start **
.auth-register__form {
  height: 1050px;
}

@media (max-width: 576px) {
  .auth-register__form {
    height: 950px;
  }
}
// ** auth-register End **

// ** contact us page Start **
@media (max-width: 991px) {
  .contact-us-details__google-map {
    height: 250px;
  }
}
// ** contact us page End **

// ** courses page start **
// courses list
.courses-list-wrapper {
  // Truncate courses titles with 2 lines
  .courses-title-truncate {
    height: 4.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  // Truncate courses content with 3 lines
  .courses-content-truncate {
    height: 4.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    .courses-title-truncate {
      height: 7.5rem;
    }

    .courses-content-truncate {
      height: 6rem;
    }
  }

  // course level
  .course-level {
    font-size: 0.8rem;
    height: 1.5rem;
  }
}

// course Sidebar
.course-sidebar {
  .blog-recent-post-title,
  .blog-category-title {
    line-height: 23px;
    letter-spacing: 0;
  }
}

// accordion-button
.accordion-button {
  font-size: 1.3rem !important;
  font-weight: 600 !important;
}

// ** My courses
// tabs

.my-courses__tabs {
  .nav-item {
    .nav-link {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom-color: #fbf1e1;
    }

    .nav-link svg {
      width: 25px;
    }

    .nav-link.active {
      color: $primary;
      border-bottom-color: $primary;
    }

    .nav-link.active svg {
      stroke: $primary;
    }
  }
}

.my-courses__dropdown:hover svg {
  stroke: $primary;
}
// ** courses page end **

// ** Dashboard start
.dashboard-info__icon svg {
  width: 25px;
}

.courses-progress-card {
  height: 2.5rem;
}

// ** Switch Language
.sidebar__menu > div.main-menu.menu-fixed {
  position: relative !important;
}

.lang-list.dropdown-menu[data-popper-placement]:not([data-popper-placement^="top-"]) {
  top: 100% !important;

  @media (max-width: 767px) {
    top: 50% !important;
  }
}

.header-navbar
  .navbar-container
  ul.navbar-nav
  li.dropdown-user
  .dropdown-menu.dropdown-menu_right {
  right: 0 !important;
  left: unset !important;
}

// ** partner logos ** \\
.partner-logos {
  .img-fluid {
    height: 100px !important;
    max-width: 160px;
    object-fit: contain;
  }

  .swiper-wrapper {
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 1 !important;
    display: flex !important;
    box-sizing: initial !important;
  }
}

footer.footer {
  padding: 0.8rem 0 0;
}
